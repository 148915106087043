import React from 'react'
import { graphql, navigate } from 'gatsby'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Container, Row, Col, Form, FormGroup, Input } from 'reactstrap'
import { arrayContainsArray, inArray } from '../utils/functions'
import { Helmet } from 'react-helmet'

import Breadcrumb from '../components/Breadcrumb'
import ScrollTop from '../components/ScrollTop'

import { htmlDecode } from '../utils/functions'

class ListMemberTemplate extends React.Component{

	constructor(props){
		super(props);
		const { data } = this.props;
		let team = data.allWordpressWpTeam.edges.filter(el =>
			el.node.categories.team === data.wordpressPage.archive_page.term_id)
			.sort((a, b) => a.node.menu_order - b.node.menu_order)
			.sort((a, b) => { return a.node.front_member ? -1 : b.node.front_member ? 1 : 0 })

		this.state = {
			filters: [],
			team: team,
			initialTeam: team,
			searchValue: '',
			showAllFilters: false
		}

		this.toggleFilter = this.toggleFilter.bind(this)
		this.submitSearch = this.submitSearch.bind(this)
		this.toggleSearch = this.toggleSearch.bind(this)
		this.updateList = this.updateList.bind(this)
	}

	toggleFilter(event){
		if(event.target.checked)
			// this.updateList({filters: [...this.state.filters, parseInt(event.target.value)]})
			this.updateList({filters: [parseInt(event.target.value)]})
		else{
			let filters = [...this.state.filters]
			filters.splice(filters.indexOf(event.target.value), 1)
			this.updateList({filters: filters})
		}
	}

	submitSearch(event){
		event.preventDefault();
	}

	toggleSearch(event){
		this.updateList({searchValue: event.target.value});
	}

	updateList(value){
		const searchValue = value.searchValue !== undefined ? value.searchValue : [...this.state.searchValue]
		// const filters = value.filters !== undefined ? value.filters : [...this.state.filters]
		const filters = value.filters

		let team = [...this.state.initialTeam]

		if(filters && filters.length > 0){
			team = team.filter((member) => {
			const categories = member.node.categories.tags
			return arrayContainsArray(categories, filters)
		})
		}

		team = team.filter((member) => {
			return (member.node.title.toString().toLowerCase().search(searchValue.toString().toLowerCase()) !== -1
				|| member.node.information.role[0].toString().toLowerCase().search(searchValue.toString().toLowerCase()) !== -1
				|| member.node.content.replace(/<\/?[^>]+(>|$)/g, "").toString().toLowerCase().search(searchValue.toString().toLowerCase()) !== -1)
		})

		this.setState({
			team,
			searchValue,
			filters
		})
	}


	render(){
		const {data, pageContext} = this.props
		let tags = []
		this.state.initialTeam.map(member => {
			member.node.categories.tags.map((term_id) => {
				tags.push(data.allWordpressWpDomain.edges.filter(el => el.node.wordpress_id === term_id)[0])
				return null
			})
			return null
		})

		tags = tags.filter((v,i) => tags.indexOf(v) === i).filter(item => item !== undefined)
		const tagsButtons = this.state.showAllFilters ? tags.filter((v,i) => tags.indexOf(v) === i).filter(item => item !== undefined) : tags.filter((v,i) => tags.indexOf(v) === i).filter(item => item !== undefined).slice(0, 5)

		return (
			<div className="page-template list-member-page-template">
				<Helmet>
					<title>{htmlDecode(data.wordpressPage.title)}</title>
					{
						data.wordpressPage.metadesc && data.wordpressPage.metadesc !== "" &&
						<meta name="description" content={data.wordpressPage.metadesc} />
					}
				</Helmet>
				<Breadcrumb title={data.wordpressPage.title} id={data.wordpressPage.wordpress_id} parent={data.wordpressPage.wordpress_parent} hierarchy={data.wordpressPage.hierarchy}/>
				<Container>
					<Row>
						<Col tag="aside" className="d-none d-lg-block" lg="4">
							<div className="anchors-sidebar h-100 pr-5">
								<div className="bg-light h-100">
								</div>
							</div>
						</Col>
						<Col tag="article" className="py-5 page-content">
							<h1 dangerouslySetInnerHTML={{__html: data.wordpressPage.title }}/>
							<div className="filter-section mb-5">
								<Form onSubmit={this.submitSearch}>
									<FormGroup className="search-group">
									  <Input className="search-input" type="text" name="search" id="search-field" placeholder="Nom, Prénom, Fonction, Délégation" onChange={this.toggleSearch} />
									</FormGroup>
								</Form>
								{tagsButtons.map(t =>
									<div className="tag" key={t.node.wordpress_id}>
										<input type="checkbox" value={t.node.wordpress_id} id={t.node.slug} onChange={this.toggleFilter} checked={inArray(t.node.wordpress_id,this.state.filters)} />
										<label htmlFor={t.node.slug}>{t.node.name}</label>
									</div>
								)}
								{
									!this.state.showAllFilters &&
									<div className="tag">
										<input type="checkbox" id="show-all-tags" onChange={() => this.setState({showAllFilters: true})} />
										<label htmlFor="show-all-tags">...</label>
									</div>
								}
							</div>
							<Row className="list-section mt-5">
							{this.state.team.map(member => {
								const img = member.node.featured_media && member.node.featured_media.wordpress_id && pageContext.images.find(e => e.wordpress_id === member.node.featured_media.wordpress_id)
								const memberImg = img && img.localFile && img.localFile.childImageSharp && img.localFile.childImageSharp.sizes

								let domains = "";
								member.node.categories.tags.slice(0, 3).map(id => {
									const tag = tags.filter(tag => tag.node.wordpress_id === id)[0]
									if ( tag && tag.node && tag.node.name && String(tag.node.name) !== "sans liste" ) {
										domains = tag ? domains.concat(tag.node.name + 'lolilol') : domains
									}
									return null
								})
								domains = domains.slice(0, domains.indexOf('lolilol')) + "...";
								
								if(member.node.front_member)
									return <Col md='12' className="tease-member-container" key={member.node.wordpress_id}>
										<div onClick={() => navigate(member.node.full_slug)} onKeyPress={() => navigate(member.node.full_slug)} role="link" className='tease-member highlighted' tabIndex={0}>
											<div className="left-column">
												{memberImg &&
													<div className="image-container">
														<Img className="member-image" sizes={memberImg}/>
													</div>
												}
												<div className="member-title">
													<h3 className="member-name">{member.node.title}</h3>
													<p className="member-role">{member.node.information.role}</p>
													<hr className="separator-grey left"/>
												</div>
											</div>

											<div className="member-infos">
												<address style={{whiteSpace : 'pre-wrap'}}>{member.node.information.address}</address>
												<p className="phone">{member.node.information.tel}</p>
											</div>

											<div className="hover-content text-center">
												<p className="link-label">Voir en détail</p>
												<hr className="separator-pink"/>
											</div>
										</div>
									</Col>
								else
									return <Col md='6' lg="4" className="tease-member-container" key={member.node.wordpress_id}>
										<div onClick={() => navigate(member.node.full_slug)} onKeyPress={() => navigate(member.node.full_slug)} role="link" className='tease-member text-center' tabIndex={0}>
											{memberImg &&
												<Img className="member-image" sizes={memberImg}/>
											}
											<h3 className="member-name">{member.node.title}</h3>
											<p className="member-role">{member.node.information.role}</p>
											<hr className="separator-grey"/>
											<p className="member-domains">{domains}</p>
											<div className="hover-content">
												<p className="link-label">Voir en détail</p>
												<hr className="separator-pink"/>
											</div>
										</div>
									</Col>
								}
							)}
							</Row>
							<ScrollTop/>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}

}

ListMemberTemplate.propTypes = {
	data: PropTypes.object.isRequired,
	edges: PropTypes.array,
}

export default ListMemberTemplate

export const pageQuery = graphql`
	query($id: String!) {
		wordpressPage(id: { eq: $id }) {
			id
			title
			wordpress_parent
			wordpress_id
			metadesc
			hierarchy {
				post_id
				post_name
				post_title
				post_parent
				post_link
				menu_order
			}
			archive_page{
				is_archive
				term_id
			}
		}
		allWordpressWpTeam{
			edges{
				node{
					wordpress_id
					menu_order
					front_member
					title
					slug
					content
					categories{
						team
						tags
					}
					full_slug
					information {
						role
						address
						tel
					}
					featured_media{
						wordpress_id
					}
				}
			}
		}
		allWordpressWpDomain{
			edges{
				node{
					wordpress_id
					count
					name
					slug
				}
			}
		}
	}
`
